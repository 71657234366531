import axios from "axios";
import { Field, Form, Formik } from "formik";
import { graphql } from "gatsby";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import EncordActiveIcon from "../assets/encord-active-icon.svg";
import EncordAnotateIcon from "../assets/encord-anotate-icon.svg";
import CustomLink from "../components/CustomLink";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { HUBSPOT_PROXY_URL, blockedDomains, emailRegex } from "../constants";
import "../styles/page/virtual-event-lp.scss";
import {
  FIRST_NAME_VALIDATION,
  LAST_NAME_VALIDATION,
  PHONE_VALIDATION,
  WORK_EMAIL_VALIDATION,
  noHtmlOrJs,
} from "../utilities/helpers";

const RequestTrialSchema = Yup.object().shape({
  firstName: FIRST_NAME_VALIDATION,
  lastName: LAST_NAME_VALIDATION,
  workEmail: WORK_EMAIL_VALIDATION,
  phone: PHONE_VALIDATION,
});

const EncordLabelSection = ({ icon, title, description }) => {
  return (
    <div
      className="mb-3 gap-5 flex flex-col sm:flex-row rounded-lg border border-solid border-gray-500 px-5 py-5 backdrop-opacity-[5px]"
      style={{ backgroundColor: "rgba(255, 255, 255, 0.08)" }}
    >
      <img src={icon} alt="img" loading="lazy" className="w-16 h-16" />
      <div className="flex flex-col">
        <div className="flex items-center gap-2 mb-1">
          <div className="text-xl font-semibold text-[#FFFFFF]">{title}</div>
        </div>
        <div className="text-sm md:text-base font-normal text-[#FFFFFF]">
          {description}
        </div>
      </div>
    </div>
  );
};

const PageContent = ({ data, location }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    const [cookie] = document.cookie
      .split("; ")
      .filter((x) => x.includes("hubspotutk="));
    const [, hubspotutk] = cookie?.split("=") || ["", ""];
    setError("");
    try {
      if (emailRegex.test(values?.workEmail)) {
        setLoading(true);

        /**
         @summary Block verify api for temporary time
          const response = await axios.get(
          `https://verifymail.io/api/${email}?key=${VERIFY_EMAIL_API_KEY}`
        );
         */

        if (
          // response?.data?.deliverable_email &&
          // response?.data?.mx &&
          // !response?.data?.disposable
          true
        ) {
          const requestPayload = {
            fields: [
              {
                objectTypeId: "0-1",
                name: "firstname",
                value: values?.firstName,
              },
              {
                objectTypeId: "0-1",
                name: "lastname",
                value: values?.lastName,
              },
              {
                objectTypeId: "0-1",
                name: "email",
                value: values?.workEmail,
              },
              {
                objectTypeId: "0-1",
                name: "phone_number__inbound2_",
                value: values?.phone,
              },
              // {
              //   objectTypeId: "0-1",
              //   name: "jobtitle",
              //   value: values?.jobTitle,
              // },
              // {
              //   objectTypeId: "0-1",
              //   name: "use_case",
              //   value: values?.useCase,
              // },
              // {
              //   objectTypeId: "0-1",
              //   name: "use_case_free_text_form",
              //   value: values?.useCaseFreeTextForm,
              // },
            ].filter((x) => x.value),
          };
          if (hubspotutk) {
            Object.assign(requestPayload, {
              context: {
                pageUri: location?.href,
                pageName: "rsna-2023-book-in-advance",
              },
            });
          }

          const response = await axios.post(
            HUBSPOT_PROXY_URL,
            Object.assign(requestPayload, {
              portalId: "25381551",
              formId: "4139bad6-49fe-48e8-9f07-fa91f5c5d231",
            })
          );
          /**
           * Append custom properties to Heap
           */
          if (window.heap) {
            window.heap.addUserProperties({
              marketing_email: values?.workEmail,
              use_case: values?.useCaseFreeTextForm,
            });
          }
          if (response?.data?.inlineMessage) {
            toast.success(
              <div
                dangerouslySetInnerHTML={{
                  __html: response?.data?.inlineMessage,
                }}
              />
            );
          }
          resetForm();
          // const _res = await window.hero.submit({
          //   email: values?.workEmail,
          //   use_case: values?.useCaseFreeTextForm,
          // });
          // await window.hero.dialog.open(_res);
        } else {
          setError("Please enter your work e-mail.");
        }
      } else {
        setError("Email must be formatted correctly.");
      }
    } catch (error) {
      if (
        error?.response?.data &&
        error?.response?.data.errors &&
        error?.response?.data.errors[0]?.message
      ) {
        const [, errorMessage] =
          error?.response?.data?.errors[0]?.message.split(". ") || ["", ""];
        if (errorMessage) {
          setError(errorMessage ?? error?.response?.data?.errors[0]?.message);
        }
      } else {
        setError("Please enter your work e-mail.");
      }
    } finally {
      setLoading(false);
    }
  };
  const _data = data?.prismicRsnaPage?.data || {};
  const [brands] =
    _data?.body?.filter((v) => v.slice_type === "trusted_brands_list") || [];

  // useEffect(() => {
  //   const handleLoad = () => {
  //     // eslint-disable-next-line no-undef
  //     window.hero = new RevenueHero({ routerId: "231" });
  //   };
  //   window.addEventListener("load", handleLoad);

  //   return () => {
  //     window.removeEventListener("load", handleLoad);
  //   };
  // }, []);

  return (
    <div className="h-screen overflow-auto try-it-free-gradient">
      <div className="2xl:max-w-[1340px] lg:max-w-7xl md:max-w-3xl mx-auto px-5 xl:px-0 2xl:px-6">
        <CustomLink
          to="/"
          className="z-10 inline-flex items-center gap-2 py-10 lg:py-12"
        >
          <span>
            <svg
              width="29"
              height="16"
              viewBox="0 0 29 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28 9C28.5523 9 29 8.55228 29 8C29 7.44772 28.5523 7 28 7V9ZM0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM28 7H1V9H28V7Z"
                fill="white"
              />
            </svg>
          </span>
          <h4 className="text-xl font-semibold text-white leading-6 tracking-[-0.04em]">
            Back
          </h4>
        </CustomLink>
      </div>

      <div className="2xl:max-w-[1340px] lg:max-w-7xl md:max-w-3xl mx-auto flex flex-col-reverse lg:flex-row md:justify-between justify-center">
        <div className="flex flex-col px-5 text-white xl:mx-0 md:mx-auto xl:px-0 2xl:px-6">
          <h1 className="hidden text-2xl font-semibold lg:block md:text-4xl">
            {_data?.page_heading.text}
          </h1>
          <h2 className="mt-6 font-medium md:text-2xl md:mt-8 text-xlg">
            {_data?.sub_header.text}
          </h2>
          <h3 className="max-w-xl mt-2 mb-10 text-sm md:text-base">
            {_data?.sub_text.text}
          </h3>

          <div className="flex flex-col justify-center max-w-2xl">
            <EncordLabelSection
              icon={EncordAnotateIcon}
              title="Encord Annotate"
              description="Efficiently label any computer vision modality and manage large scale annotation teams with customizable workflows."
            />
            <EncordLabelSection
              icon={EncordActiveIcon}
              title="Encord Active"
              description="Orchestrate active learning pipelines and improve model accuracy by identifying errors and biases in your data, labels and models."
            />
            <div className="border-b border-solid border-b-white my-11" />
            <section className="pb-6 mx-auto lg:pb-0">
              <div className="px-2 text-sm font-semibold text-center text-white lg:px-4 md:px-3">
                Trusted by the world's best AI teams
              </div>
              <div className="grid grid-cols-3 md:gap-8 gap-4 md:grid-cols-6 lg:pt-[32px] md:pt-[15px] pt-[10px] lg:px-4 md:px-3 px-2 mt-6 lg:mt-0">
                {brands?.items?.map((item, index) => {
                  return (
                    <div
                      key={`brand_${index}`}
                      className="flex justify-center col-span-1 md:col-span-2 lg:col-span-1"
                    >
                      <img
                        src={item?.avatar?.url}
                        alt={"brand logo"}
                        className={`${"object-scale-down h-auto md:w-auto w-[100px]"} img_grayscale`}
                        loading="lazy"
                        height={0}
                        width={0}
                      />
                    </div>
                  );
                })}
              </div>
            </section>
          </div>
        </div>

        <div className="max-w-[700px] self-center xl:my-0 my-5 px-5 xl:px-0 lg:!mb-auto">
          <h1 className="block mb-6 text-2xl font-semibold text-white lg:hidden md:mb-14 md:text-4xl">
            {_data?.page_heading.text}
          </h1>
          <section className="flex flex-col px-5 py-7 lg::py-14 lg:max-w-[34rem] max-w-[32rem] w-full rounded-2xl form_shadow">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                workEmail: "",
                phone: "",
                jobTitle: "",
                useCase: "",
                useCaseFreeTextForm: "",
              }}
              validationSchema={RequestTrialSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                touched,
                values,
                setTouched,
                validateForm,
                setFieldValue,
                form,
                ...rest
              }) => {
                return (
                  <Form className="commercial-form">
                    <span>
                      {/* <div className="mb-4 text-sm font-normal text-purple-1100 lg:text-base lg:mb-10 md:mb-6">
                      Enter your details below to arrange a free trial:
                    </div> */}

                      <div className="flex flex-col gap-3 lg:gap-5 lg:flex-row">
                        <div className="flex flex-col w-full">
                          <label className="text-gray-600 placeholder:text-[#a8a4a4] text-xs md:text-sm lg:text-base   font-normal mb-1">
                            First name
                            <sup className="text-red-500">*</sup>
                          </label>
                          <Field
                            name="firstName"
                            placeholder="Enter your first name"
                            type="text"
                            className="text-gray-600 placeholder:text-[#a8a4a4] text-xs md:text-sm lg:text-base   font-normal rounded-3xl placeholder-gray-1600 border-solid border-gray-1400 border-[0.5px]"
                          />
                          {errors.firstName && touched.firstName ? (
                            <small className="text-red-500">
                              {errors.firstName}
                            </small>
                          ) : null}
                        </div>

                        <div className="flex flex-col w-full">
                          <label className="text-gray-600 placeholder:text-[#a8a4a4] text-xs md:text-sm lg:text-base    font-normal mb-1">
                            Last name
                            <sup className="text-red-500">*</sup>
                          </label>
                          <Field
                            name="lastName"
                            placeholder="Enter your last name"
                            type="text"
                            className="text-gray-600 placeholder:text-[#a8a4a4] text-xs md:text-sm lg:text-base w-52  font-normal rounded-3xl placeholder-gray-1600 border-solid border-gray-1400 border-[0.5px]"
                          />
                          {errors.lastName && touched.lastName ? (
                            <small className="text-red-500">
                              {errors.lastName}
                            </small>
                          ) : null}
                        </div>
                      </div>

                      <div className="flex flex-col gap-3 lg:gap-5 lg:flex-row">
                        <div className="flex flex-col flex-1 w-full mt-4 lg:mt-5">
                          <label className="text-gray-600 placeholder:text-[#a8a4a4] text-xs md:text-sm lg:text-base   font-normal mb-1">
                            Work email
                            <sup className="text-red-500">*</sup>
                          </label>
                          <Field
                            name="workEmail"
                            placeholder="Enter your work email"
                            type="text"
                            className="text-gray-600 placeholder:text-[#a8a4a4] text-xs md:text-sm lg:text-base   font-normal rounded-3xl placeholder-gray-1600 border-solid border-gray-1400 border-[0.5px]"
                          />
                          {errors.workEmail && touched.workEmail ? (
                            <small className="text-red-500">
                              {errors.workEmail}
                            </small>
                          ) : null}
                        </div>

                        <div className="flex flex-col flex-1 w-full mt-4 lg:mt-5">
                          <label className="text-gray-600placeholder:text-[#a8a4a4] text-xs md:text-sm lg:text-base   font-normal mb-1">
                            Phone number
                          </label>

                          <PhoneInput
                            inputClass="text-gray-600 placeholder:text-[#a8a4a4] !text-xs md:!text-sm lg:!text-base   font-normal rounded-3xl placeholder-gray-1600 border-solid border-gray-1400 border-[0.5px]"
                            country={"us"}
                            value={values.phone}
                            placeholder="Enter your phone number"
                            inputProps={{
                              name: "phone",
                            }}
                            onChange={(phoneNumber, country, e) => {
                              setFieldValue("phone", phoneNumber);
                            }}
                          />
                          {errors.phone && touched.phone ? (
                            <small className="text-red-500">
                              {errors.phone}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      {error && <small className="text-red-500">{error}</small>}
                    </span>
                    <span>
                      {/* <div className="flex flex-col gap-3 lg:gap-5 lg:flex-row">
                        <div className="flex flex-col flex-1 w-full mt-4 lg:mt-5">
                          <label className="text-gray-600 placeholder:text-[#a8a4a4] text-xs md:text-sm lg:text-base   font-normal mb-1">
                            Job title
                          </label>
                          <Field
                            name="jobTitle"
                            placeholder="Enter your role"
                            type="text"
                            className="text-gray-600 placeholder:text-[#a8a4a4] text-xs md:text-sm lg:text-base   font-normal rounded-3xl placeholder-gray-1600 border-solid border-gray-1400 border-[0.5px]"
                          />
                          {errors.jobTitle && touched.jobTitle ? (
                            <small className="text-red-500">
                              {errors.jobTitle}
                            </small>
                          ) : null}
                        </div>
                        <div className="flex flex-col flex-1 w-full mt-4 lg:mt-5">
                          <label className="text-gray-600 placeholder:text-[#a8a4a4] text-xs md:text-sm lg:text-base   font-normal mb-1">
                            Main use case
                          </label>
                          <Field
                            as="select"
                            className="text-gray-600 placeholder:text-[#a8a4a4] text-xs md:text-sm lg:text-base w-full font-normal rounded-3xl placeholder-gray-1600 border-solid border-gray-1400 border-[0.5px]"
                            name="useCase"
                          >
                            <option disabled="" value="">
                              Please select
                            </option>
                            <option value="Video annotation">
                              Video annotation
                            </option>
                            <option value="Image annotation">
                              Image annotation
                            </option>
                            <option value="Medical image annotation">
                              Medical image annotation
                            </option>
                            <option value="Build active learning pipelines">
                              Build active learning pipelines
                            </option>
                            <option value="Automate annotation">
                              Automate annotation
                            </option>
                            <option value="Train models">Train models</option>
                            <option value="Model validation">
                              Model validation
                            </option>
                            <option value="AI debugging">AI debugging</option>
                            <option value="Other use case">
                              Other use case
                            </option>
                          </Field>
                          {errors.useCase && touched.useCase ? (
                            <small className="text-red-500">
                              {errors.useCase}
                            </small>
                          ) : null}
                        </div>
                      </div> */}

                      {/* <div className="flex flex-col mt-4 lg:mt-5">
                        <label className="text-gray-600 placeholder:text-[#a8a4a4] text-xs md:text-sm lg:text-base   font-normal mb-1">
                          Anything more you’d like to share?
                        </label>
                        <Field
                          as="textarea"
                          name="useCaseFreeTextForm"
                          placeholder="Tell us more about what you’re trying to achieve"
                          className="text-gray-600 placeholder:text-[#a8a4a4] min-h-[94px] text-xs md:text-sm lg:text-base   font-normal rounded-3xl placeholder-gray-1600 border-solid border-gray-1400 border-[0.5px]"
                        />
                        {errors.useCaseFreeTextForm &&
                        touched.useCaseFreeTextForm ? (
                          <small className="text-red-500">
                            {errors.useCaseFreeTextForm}
                          </small>
                        ) : null}
                      </div> */}

                      <div className="flex gap-5">
                        <button
                          type={
                            Object.keys(errors)?.length ? "button" : "submit"
                          }
                          disabled={loading}
                          className="bg-[#5658dd] font-semibold border-[#8082e6] text-white py-2 text-xs md:text-sm lg:text-base shadow-lg rounded-full max-w-[292px] w-full mx-auto my-7 hover:border-[#6362bf] hover:bg-[#6362bf]"
                          {...rest}
                        >
                          {loading ? (
                            <div className="flex justify-center w-5 h-5 m-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent"></div>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </span>
                  </Form>
                );
              }}
            </Formik>

            <div
              className="  text-xs font-normal text-[#434343] lg:mx-2 md:mx-1 mx-[2px] trail_anchor_link"
              dangerouslySetInnerHTML={{
                __html: _data?.form_bottom_text?.html,
              }}
            ></div>
          </section>
        </div>
      </div>
    </div>
  );
};

const Rsna2023BookInAdvancePage = ({ location, data }) => {
  return (
    <Layout location={location} hideFooter hideHeader>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <script
          type="text/javascript"
          src="https://app.revenuehero.io/scheduler.min.js"
        ></script>
      </Helmet>
      <PageContent data={data} />
    </Layout>
  );
};

export const Head = ({ data }) => {
  const _data = data?.prismicRsnaPage?.data || {};
  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};

export const pageQuery = graphql`
  query PrismicRsnaPage($uid: String!) {
    prismicRsnaPage(uid: { eq: $uid }) {
      data {
        page_heading {
          text
        }
        sub_header {
          text
        }
        sub_text {
          text
        }
        form_bottom_text {
          text
          html
        }
        meta_heading {
          text
        }
        meta_description {
          text
        }
        body {
          ... on PrismicRsnaPageDataBodyTrustedBrandsList {
            id
            slice_type
            items {
              avatar {
                url
              }
            }
          }
        }
      }
    }
  }
`;
export default Rsna2023BookInAdvancePage;
